import * as React from 'react';
import styled from '../../settings/styled';
import Hero from '../../components/Hero';
import Container from '../../components/Container';
import image from '../../static/Fjelltur_Skottland.png';
import bg from '../../static/bg3.png';
import Section from '../../components/Section';
import Spacer from '../../components/Spacer';

const ImageWrapper = styled.div`
  margin-top: -${props => props.theme.spacing.large};
  padding: 2rem;
  background-color: ${props => props.theme.palette.primary};

  img {
    border-radius: 4px;
    border: 1px solid ${props => props.theme.palette.lightGray};
  }
`;

const InfoBox = styled.div`
  text-align: center;
  padding: ${props => props.theme.spacing.large};
  box-shadow: 7px 9px 22px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.palette.white};
`;

const BoxTitle = styled.h2`
  font-size: 2.5rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 3px;
    background-color: ${props => props.theme.palette.secondary};
    bottom: -${props => props.theme.spacing.small};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Boxtext = styled.p``;

export default function FrontPage() {
  return (
    <>
      <div
        style={{
          background: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <Hero />
        <Container>
          <ImageWrapper>
            <img src={image} alt="" />
          </ImageWrapper>
        </Container>
      </div>
      <Spacer variant="normal" />
      <Section>
        <Container small>
          <InfoBox>
            <BoxTitle>Vi skal gifte oss!</BoxTitle>
            <Boxtext>
              Vi gifter oss i Balke Kirke 17. august! Hvis du har fått adressen
              til denne siden, er sjansen stor for at du også skal være der den
              dagen. I så fall finner du her litt praktisk informasjon,
              kombinert med et lite gløtt inn i hvordan vår reise har vært frem
              til nå.
            </Boxtext>
          </InfoBox>
        </Container>
      </Section>
    </>
  );
}
