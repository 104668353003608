import * as React from 'react';
import styled from '../../settings/styled';
import { fadeIn } from '../../settings/animations';

const Wrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${props => props.theme.spacing.large};
  padding-bottom: ${props => props.theme.spacing.xlarge};
  /*background-color: ${props => props.theme.palette.white};*/
  color: ${props => props.theme.palette.black};
  align-items: center;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 5rem;
  margin: 0;
  letter-spacing: 8px;
  font-weight: 500;
  animation: ${fadeIn} 0.5s cubic-bezier(0.25, 0.46, 0.185, 0.95) 0.2s both;

  @media all and (max-width: 960px) {
    font-size: 3rem;
    letter-spacing: 5px;
  }

  @media all and (max-width: 600px) {
    font-size: 2.5rem;
    letter-spacing: 2px;
  }
`;

const PrettyLine = styled.div`
  width: 80px;
  height: 1px;
  background-color: ${props => props.theme.palette.black};
  margin: 0 ${props => props.theme.spacing.normal};
  animation: ${fadeIn} 0.5s cubic-bezier(0.25, 0.46, 0.185, 0.95) 0.2s both;

  @media all and (max-width: 600px) {
    display: none;
  }
`;

export default function Hero() {
  return (
    <Wrapper>
      <PrettyLine />
      <Title>Synne & Tor Erik</Title>
      <PrettyLine />
    </Wrapper>
  );
}
