import { createGlobalStyle } from '../../settings/styled';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.fonts.primary};
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  h1,h2, h3,h4,h5,h6 {
    font-family: ${props => props.theme.fonts.display};
    font-weight: 700;
    letter-spacing: 1px;
  }

  p, strong, em, b {
    font-size: 1.125rem;
    letter-spacing: 1px;
    line-height: 30px;
  }
`;

export default GlobalStyles;
