import { keyframes } from './styled';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1,
    transform: translateY(0);
  }
`;

const fadeInRegular = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1,
  }
`;

export { fadeIn, fadeInRegular };
