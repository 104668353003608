import * as React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import FrontPage from './views/FrontPage';
import Header from './components/Header';
import Footer from './components/Footer';
import History from './views/History';
import Info from './views/Info';

export default function App() {
  return (
    <BrowserRouter>
      <>
        <Header />
        <Switch>
          <Route path="/" exact component={FrontPage} />
          <Route path="/historien" exact component={History} />
          <Route path="/info" exact component={Info} />
        </Switch>
        <Footer />
      </>
    </BrowserRouter>
  );
}
