import styled, { css } from '../../settings/styled';

interface ContainerProps {
  small?: boolean;
}

const Container = styled.div<ContainerProps>`
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 ${props => props.theme.spacing.small};

  ${props =>
    props.small &&
    css`
      max-width: 700px;
    `};

  img {
    width: 100%;
    max-width: 100%;
  }
`;

export default Container;
