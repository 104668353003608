import ThemeInterface from '../types/theme';

const theme: ThemeInterface = {
  palette: {
    primary: ' #F8F6F5',
    lightGray: '#f9f9f9',
    secondary: '#B49A76',
    white: '#fff',
    black: '#444'
  },
  fonts: {
    primary: '"Karla", sans-serif',
    display: '"Cormorant Garamond", serif'
  },
  spacing: {
    tiny: '8px',
    small: '16px',
    normal: '24px',
    large: '48px',
    xlarge: '98px'
  }
};

export default theme;
