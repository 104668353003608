import styled, { css } from '../../settings/styled';

type Variant = 'primary' | 'secondary' | 'white' | 'black';

const Section = styled.div<{
  variant?: Variant;
  smallSpacing?: boolean;
  bg?: string;
}>`
  width: 100%;
  padding: ${props => props.theme.spacing.xlarge} 0;

  ${props =>
    props.smallSpacing &&
    css`
      padding: ${props.theme.spacing.normal} 0;
    `};

  @media all and (max-width: 700px) {
    padding: ${props => props.theme.spacing.normal} 0;
  }

  ${props =>
    props.variant &&
    css`
      background-color: ${props.theme.palette[props.variant]};
    `};

  ${props =>
    props.bg &&
    css`
      background: url(${props.bg}) no-repeat;
      background-size: cover;
      background-position: center;
    `};
`;

export default Section;
