import React from 'react';
import styled from '../../settings/styled';
import Section from '../../components/Section';
import Container from '../../components/Container';
import weddingIcon from '../../static/wedding.png';
import weddingCakeIcon from '../../static/cake.png';

const Flexer = styled.div`
  display: flex;
  margin: 0 -${props => props.theme.spacing.normal};
  padding: 5rem 0;

  @media all and (max-width: 700px) {
    flex-direction: column;
    padding: 2rem 0;
  }

  > div {
    padding: 0 ${props => props.theme.spacing.normal};
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:first-child {
      border-right: 1px solid ${props => props.theme.palette.secondary};

      @media all and (max-width: 700px) {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid ${props => props.theme.palette.secondary};
      }
    }

    h2 {
      margin-bottom: 0;
      font-size: 2rem;
    }

    @media all and (max-width: 700px) {
      flex-basis: 100%;
    }
  }
`;

export default function Info() {
  return (
    <>
      <Flexer>
        <div>
          <img src={weddingIcon} alt="Vielsen" />
          <h2>Vielsen</h2>
          <p>Balke kirke klokken 14.00</p>
        </div>
        <div>
          <img src={weddingCakeIcon} alt="Festen" />
          <h2>Festen</h2>
          <p>Kapp melkefabrikk klokken 16.00</p>
        </div>
      </Flexer>
      <Section variant="primary">
        <Container>
          <h2>Hvor ligger Balke kirke?</h2>
          <p>
            Siden halvparten av gjestelisten har varierende kunnskap om Toten og
            omegn, kan det være greit med litt hjelp. Uansett om du kommer fra
            sør eller nord, bør du velge fylkesveg 33. Sørfra passerer du
            Skreia, mens nordfra suser du forbi Bilitt. Midt i mellom ligger en
            skiltet avkjøring til Balke kirke.
          </p>
          <p>
            Følg veien (og synet av kirken) til du står på parkeringsplassen.
            Derfra bør det gå ganske greit. Nyt gjerne utsikten over Mjøsas
            vakreste krok, nemlig Totenvika. Vær obs! Det er fort gjort å glemme
            tid og sted, så pass på hverandre slik at alle kommer seg inn i
            kirken i tide.
          </p>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>Hvor finner jeg Kapp melkefabrikk?</h2>
          <p>
            Etter endt vielse går turen omtrent ti kilometer nordover til
            melkefabrikken. Adressen er Fabrikkvegen, 2849 Kapp. Fra kirken
            anbefaler vi alle ikke-totninger å svinge til høyre ut på fylkesveg
            33.
          </p>
          <p>
            Deretter har du tre avkjøringer etter hverandre som alle leder til
            Kapp, og veien er omtrent like lang uansett hvilken du velger.
            Skulle du slumpe til å misse alle tre fordi du nyter omgivelsene, er
            det likevel ingen grunn til bekymring. Du får tre nye muligheter før
            du plutselig befinner deg i Gjøvik.
          </p>
          <p>
            Vel framme i Kapp vil du se en skiltet avkjøring mot melkefabrikken.
            I dette krysset ligger forresten Hårhjørnet, et godt egnet
            etablissement for deg som føler at manken trenger en liten
            behandling før kvelden.
          </p>
          <p>
            Vi ser helst at du ikke lar deg friste av skiltet mot Festiviteten,
            som ligger fem kilometer i motsatt retning. Det er på melkefabrikken
            det skjer i kveld.
          </p>
        </Container>
      </Section>
      <Section variant="primary">
        <Container>
          <h2>Ønskeliste</h2>
          <p>
            Vi ønsker at så mange som mulig av de inviterte har anledning til å
            komme, og det er i grunn det viktigste. Vi trenger egentlig ingen
            flere «ting» for å komme i gang med livet, siden vi har essensielt
            utstyr som bestikk, toastjern og TV.
          </p>
          <p>
            Skulle du likevel føle en trang til å gi noe, hadde vi satt stort
            pris på at gaven er valutabasert. Vi er fullt klar over at mange av
            gjestene må bruke en del penger på reise, overnatting, mat og så
            videre, så ikke føl noe press på gavens størrelse!
          </p>
          <p>Egnet kontonummer kommer snart.</p>
        </Container>
      </Section>
      <Section>
        <Container>
          <h2>Kleskode/antrekk</h2>
          <p>
            Pent, men unngå gjerne hvite kjoler for brudens skyld. Med andre
            ord: Kle deg som om du skal i bryllup.
          </p>
        </Container>
      </Section>
      <Section variant="primary">
        <Container>
          <h2>Overnattingsmuligheter</h2>
          <p>
            Et steinkast unna festlokalet ligger Hekshusstranda Camping, som
            reklamerer med et lite utvalg av hytter.{' '}
            <a
              href="https://www.hekshusstrandacamping.no/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Du finner kæmpingen her!
            </a>
          </p>
          <p>
            Nest nærmest er Hoff Gjestgiveri. Fortell gjerne at du skal i
            “brølløppet ’a Synne” når du ringer, så slipper du å vente på at de
            skal bekrefte med bruden først.{' '}
            <a
              href="https://www.hoffgjestgiveri.com/overnatting-pa-lena-12567s.html?show=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Les om gjestgiveriet her!
            </a>
          </p>
          <p>
            Til slutt har vi det antatt heteste alternativet, og det er
            overnatting i Gjøvik sentrum. Det ligger 15 km fra Kapp
            Melkefabrikk, og sjansen er stor for at mange skal samme vei når det
            er tomt for drikke.
          </p>
        </Container>
      </Section>
    </>
  );
}
