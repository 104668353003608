import * as React from 'react';
import Container from '../../components/Container';
import pictureOne from '../../static/forsidebryllup_min.png';
import pictureTwo from '../../static/parkbenk.png';
import pictureThree from '../../static/Torget.png';
import styled from '../../settings/styled';

const Heading = styled.h1`
  font-size: 3rem;
`;

export default function History() {
  return (
    <>
      <Container small>
        <Heading>Historien om Synne og Tor Erik</Heading>

        <h2>Det første møtet</h2>
        <p>
          I 2011 møttes folk som regel på en av tre arenaer; via venner, på jobb
          eller på fest. Synne og Tor Erik var så heldige at de hadde venner som
          jobbet sammen, og denne kvelden arrangerte vennene fest på jobben,
          nærmere bestemt i spillmagasinet Gamereactor.
        </p>
        <p>
          Tor Erik hadde tatt på seg «finstasen», komplett med superundertøy og
          fjellsko (som for øvrig gjorde ham fem centimeter høyere). Han var den
          første av de to til å ankomme kontorfesten i en bakgård i Oslo
          sentrum, og fikk dermed gleden av å se den mørkhårede skjønnheten
          ankomme. «Dæggær’n!», tenkte Tor Erik, som ikke klarte å ta øynene
          bort fra den åtte år yngre kvinnen.
        </p>
        <p>
          Til stede på denne festen var Morten, Line og Kristian (Tor Eriks
          forlover). Sistnevnte hadde invitert Tor Erik, mens det var Lines
          frykt for å gå glipp av en fest som gjorde at jentekvelden med Synne
          ble flyttet til kontoret. Morten hadde egentlig ikke ansvar for noe
          her, men vi tenkte det var hyggelig å nevne ham likevel.
        </p>
      </Container>
      <Container>
        <img src={pictureTwo} alt="" />
      </Container>
      <Container small>
        <h2>Sjarmoffensiv i Rådhusgata 9</h2>
        <p>
          Dette var en fredag, så Synne hadde naturligvis med seg taco som måtte
          tilberedes. Resten hadde nok med å konkurrere i spill og diskutere
          grafikk vs gameplay, men Tor Erik holdt et lite øye med hun som lagde
          mat. «Bruker hun ikke veldig høy varme?», undret han for seg selv.
        </p>
        <p>
          Etter litt taco og alkohol var det duket for tvungen stand-up i regi
          av Kristian. Her leverte Tor Erik helt sånn passe under middels
          morsomt, mens Synne lot til å mistrives litt mer på scenen. Det var da
          Tor Erik klinket til med sitt eneste sjekketriks. Han var hyggelig, og
          ga komplimenter til den uerfarne «komikeren».
        </p>
        <p>
          Synne hadde visst lagt merke til denne raringen fra Østfold, og hun
          bet seg spesielt merke i det hun tolket som et skikkelig kompliment.
          På vei ut for ta seg noen trekk, hørte hun nemlig Tor Erik si «røyker
          Synne?!», og det at det var sjokkerende at hun kunne ha en slik uvane
          gjorde at hun smeltet fullstendig.
        </p>
        <h2>Der ingen skulle tru at noken kunne ha det hyggeleg</h2>
        <p>
          Tor Erik trodde aldri at han hadde noen sjanse med dette vakre
          vesenet, så han hadde egentlig gitt opp før han i det hele tatt hadde
          begynt. Men, på vei ut av bakgården, idet de passerte Thon Hotel Oslo
          Panorama, grep en kvinne på høye hæler tak i armen hans. Det var Synne
          som, i påskudd av at det var litt dårlig føre, ønsket noen å støtte
          seg på.
        </p>
        <p>
          Synne tok initiativet til en samtale, og åpnet med å spørre om hva Tor
          Erik drev med. «Sykemeldt fra en arbeidsplass som har gått konkurs»,
          smalt det fra Tor Erik. «Åh, så interessant», svarte Synne, som ifølge
          Tor Erik tok et litt fastere grep i armen hans. De neste 15-20
          minuttene med vandreprat gikk unna på et blunk.
        </p>
        <p>
          Fremme på Grønland innså Tor Erik at han hadde en flaske vin i sekken,
          så han og Synne ble enige om å finne et koselig sted å nyte den før de
          fulgte etter resten av gjengen. På en avsidesliggende lasterampe i
          Lakkegata ble de to sittende, og de delte både vin og historier til
          klokken ble altfor mye.
        </p>
      </Container>
      <Container>
        <img src={pictureOne} alt="" />
      </Container>
      <Container small>
        <h2>Fra SMS-duell til date</h2>
        <p>
          En uke senere sto Synne og tømte en handlepose. Det var
          kyllingkjøttdeig, grønnsaker og taco-krydder. Blant alle «hva skal du
          i kveld, da?»-meldingene tikket det inn en fra Tor Erik. Hun bestemte
          seg for å la svaret vente til det hadde gått noen dager.
        </p>
        <p>
          Senere den kvelden møtte hun noen venner, og da hun søkte råd fikk hun
          beskjed om å styre unna han fyren der. Heldigvis har Synne vondt for å
          ta imot subjektive instrukser, så etter noen dager kom svaret. Resten
          er historie.
        </p>
        <p>
          Neida, det ble en lang SMS-duell der ingen ville svare før etter noen
          dager, så det tok naturligvis en stund før den første daten var et
          faktum.
        </p>
        <p>
          Synne inviterte på middag og Gullrekka, men fredag til tross ble det
          ikke servert taco. Likevel ble de enige om å møtes igjen, og igjen og
          igjen.
        </p>
        <h2>Samboere</h2>
        <p>
          Tiden gikk fort frem mot sommeren, og de to tilbragte stadig mer tid
          sammen. Da august kom sto Synne uten et sted å bo, og sjenerøs som han
          var tilbød Tor Erik henne opphold hjemme hos ham.
        </p>
        <p>
          Synne hadde egentlig ikke tenkt til å havne i noe nytt forhold, og hun
          hadde vært ekstra tydelig på at hun ikke likte katter. Plutselig var
          hun både samboer og hadde tre pusejenter. Tor Erik har alltid tolket
          dette som et bevis på at hun var hodestups forelsket, noe hun
          sannsynligvis også var (objektiv anmerkning fra forfatteren).
        </p>
        <h2>På flyttefot</h2>
        <p>
          Året etter flyttet de to til en leilighet på Majorstuen, hvor de ble
          værende i litt over to år. I løpet av denne perioden fikk de gitt bort
          kattene til nye, flotte eiere, Synne lærte seg selv å kjenne på
          interrail og Tor Erik stekte sin første kalkun. I 2014 startet Synne
          på et master-løp innen farmasi, og har aldri sett seg tilbake.
        </p>
        <p>
          Deretter flyttet de inn i toppetasjen i Hedmarksgata 10B, med utsikt
          over byen og OL-anlegget på Jordal. I disse omgivelsene blomstret enda
          en knopp på kjærlighetens blomst, og Tor Erik tok mot til seg og
          fridde. Synne sa (naturligvis) ja.
        </p>
        <p>
          Oppholdet på Kampen/Vålerenga var flott, men de to innså etter hvert
          at det var vanskelig å spare penger mens man leide i Oslo på en
          halvannen inntekt.
        </p>
        <p>
          Tilbudet om å flytte inn i kjelleren til Tor Eriks mamma og pappa ble
          dermed diskutert tidlig og sent, og til slutt slo de til.
        </p>
      </Container>
      <Container>
        <img src={pictureThree} alt="" />
      </Container>
      <Container small>
        <h2>Nok er nok er nok</h2>
        <p>
          Åse og Roald (brudgommens foreldre) oppførte seg bortimot eksemplarisk
          det året «ungdommen» bodde i kjelleren, og vartet opp med både bil og
          pølsefredag.
        </p>
        <p>
          Det var likevel ikke nok, og de to søkte tilbake til storbyen. Det
          skulle imidlertid vise seg at heller ikke en leilighet på markagrensa
          var nok for de to, så de begynte å gå på visninger. Først i Oslo,
          deretter i Moss.
        </p>
        <p>
          Lang historie kort: De fant leiligheten de ville ha, og de kjøpte den.
          Der bor de den dag i dag.
        </p>
        <h2>Et hav av gode opplevelser</h2>
        <p>
          Den største styrken i forholdet mellom Synne og Tor Erik er at de har
          det veldig gøy sammen. Alle opplevelsene deres har blitt bedre nettopp
          fordi de har delt dem med hverandre.
        </p>
        <p>
          Sammen har de gått utallige skiturer, lagd taco og hyttebiff på
          Bamsebu i Etnedal, reist med tog gjennom Italia, løpt rundt og rundt
          Sognsvann, reist på cruise med Color Line til Kiel og spist verdens
          beste andebryst på Costa Brava.
        </p>
        <p>
          De har det gøy. De ler av hverandres humor, og de ler sammen av de
          samme tingene. Selvsagt har de også kranglet, sutret og vært skuffet
          av hverandre, men når alt kommer til alt er det ingen tvil om at disse
          to hører sammen. Det er også derfor de nå skal gifte seg.
        </p>
        <p>Takk for at du leste, og takk for at du vil være sammen med oss!</p>
      </Container>
    </>
  );
}
