import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Brand from '../Brand';
import styled from '../../settings/styled';
import { fadeIn } from '../../settings/animations';

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${props => props.theme.spacing.normal};
  height: 60px;
  background-color: ${props => props.theme.palette.secondary};
  color: ${props => props.theme.palette.white};
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  animation: ${fadeIn} 0.5s cubic-bezier(0.25, 0.46, 0.185, 0.95) 0.2s both;
`;

const ListItem = styled.li`
  margin-left: ${props => props.theme.spacing.small};

  &:first-child {
    border-right: 1px solid ${props => props.theme.palette.white};
    padding-right: ${props => props.theme.spacing.small};
  }
`;

const ListItemLink = styled(({ ...props }) => <NavLink {...props} />)`
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.palette.white};
  letter-spacing: 1px;
  text-decoration: none;
  transition: border-bottom 0.1s ease-in-out;

  &.active,
  &:hover {
    border-bottom: 1px solid ${props => props.theme.palette.white};
  }
`;

export default function Header() {
  return (
    <Wrapper>
      <Brand inverted to="/">
        S&TE
      </Brand>
      <Menu>
        <ListItem>
          <ListItemLink exact={true} to="/historien">
            Historien
          </ListItemLink>
        </ListItem>
        <ListItem>
          <ListItemLink exact={true} to="/info">
            Info
          </ListItemLink>
        </ListItem>
      </Menu>
    </Wrapper>
  );
}
