import * as React from 'react';
import styled from '../../settings/styled';
import { NavLink } from 'react-router-dom';
import { fadeIn } from '../../settings/animations';

const Brand = styled(({ inverted, ...props }) => <NavLink {...props} />)<{
  inverted?: boolean;
}>`
  font-family: ${props => props.theme.fonts.display};
  color: ${props =>
    props.inverted ? props.theme.palette.white : props.theme.palette.black};
  font-size: 1.5rem;
  text-decoration: none;
  animation: ${fadeIn} 0.5s cubic-bezier(0.25, 0.46, 0.185, 0.95) 0.2s both;
`;

export default Brand;
