import * as React from 'react';
import styled from '../../settings/styled';
import Brand from '../Brand';

const Wrapper = styled.footer`
  padding: ${props => props.theme.spacing.normal} 0;
  text-align: center;
`;

export default function Footer() {
  return (
    <Wrapper>
      <Brand to="/">S&TE</Brand>
    </Wrapper>
  );
}
